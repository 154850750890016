@import 'theme.scss';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.lista-opciones {
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu {
  min-width: inherit !important;
}

.tooltip-filtros {
  background-color: 'white' !important;
}

.estilos-vacio {
  all: initial;
}

.nodoChatbot {
  min-width: 100px;
  max-width: 350px;
  width: 100%;
}

.filtros-condicion {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  line-height: 19.5px;
  text-align: left;
  text-indent: 0px;
  text-size-adjust: 100%;
  vertical-align: bottom;
  display: inline-block;
  white-space: nowrap;
}
@media (min-width: 641px) {
  .tabla-datos {
    overflow: inherit !important;
  }
}
@media (max-width: 640px) {
  .tabla-datos .dropdown {
    position: static !important;
  }
}

$bg-color: white;
$dot-color: rgb(224,224,224);

// Dimensions
$dot-size: 0.5px;
$dot-space: 10px;

.puntos {
  background: linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
    linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center, $dot-color !important;
  background-size: $dot-space $dot-space !important;
  padding-left: 3vw !important;
}

.listado-idiomas {
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  border-color: 'transparent';
}

/* .react-bootstrap-table{
overflow-x:auto !important;
overflow-y: hidden !important;
min-height: 200px;
} */

@use 'sass:color';

.ruleGroup {
  display: flex;
  flex-flow: column;
}

.rule {
  background-color: transparent !important;
  border: 1px solid #d9d8d7;
  border-radius: 6px;
  padding: 3px;
  padding-left: 6px;
  margin: 2px;
  margin-bottom: 4px;
}

.rule:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.rule-value {
  background-color: transparent !important;
  width: 14vw !important;
  border: 1px solid #d9d8d7;
  border-radius: 6px;
}

.rule-remove {
  border: none !important;
  background-color: transparent !important;
  padding-left: 1vw !important;
}

.rule-remove > i {
  color: black !important;
}

.ruleGroup-remove {
  border: none !important;
  background-color: transparent !important;
  padding-left: 1vw !important;
  float: right;
}

.ruleGroup-remove > i {
  color: black !important;
}

.rule-operators,
.rule-value,
.betweenRules {
  margin-left: 1vw !important;
}

.ruleGroup-remove {
  color: black !important;
  background-color: transparent !important;
  margin-left: auto !important;
  color: black !important;
  background-color: transparent !important;
  text-transform: none !important;
  box-shadow: none !important;
}

.ruleGroup-header {
  order: 2;
}

.ruleGroup-body {
  order: 1;
}

.queryBuilder > .ruleGroup {
  border: none;
  border-radius: none;
  padding: 14px;
  row-gap: 14px !important;
  margin: 6px !important;
}

.ruleGroup {
  border: 1px solid #d9d8d7;
  border-radius: 3px;
  padding: 14px;
  row-gap: 14px !important;
  margin: 6px !important;
}

.ruleGroup-addRule {
  background-color: #eaf0f6 !important;
  border-color: #cbd6e2 !important;
  color: #506e91 !important;
}

.ruleGroup-addGroup {
  background-color: #eaf0f6 !important;
  border-color: #cbd6e2 !important;
  color: #506e91 !important;
}

.ruleGroup-addRule:hover {
  background-color: #f5f8fa !important;
  color: #506e91 !important;
}

.ruleGroup-addGroup:hover {
  background-color: #f5f8fa !important;
  color: #506e91 !important;
}

.svg-font-color svg > path {
  fill: var(--ifm-font-color-base);
}

.queryBuilder {
  min-width: 420px;
}

// Styles for when "Use validation" option is selected
.validateQuery {
  .queryBuilder {
    // Invalid groups
    .ruleGroup.queryBuilder-invalid {
      background-color: #66339966; // transluscent rebeccapurple

      // Bold the +Rule button if the group has no child rules or groups
      .ruleGroup-addRule {
        font-weight: bold !important;
        background-color: red !important;
      }

      // Message to user about empty groups
      & > .ruleGroup-header::after {
        content: 'Empty groups are considered invalid. Avoid them by using addRuleToNewGroups.';
        color: white;
      }
    }

    // Invalid rules
    .rule.queryBuilder-invalid {
      // Purple background for empty text fields
      .rule-value {
        background-color: #66339966; // transluscent rebeccapurple
        &::placeholder {
          color: color.scale(rebeccapurple, $lightness: -30%);
        }
      }
    }
  }
}

// Styles for when "Use validation" option is selected (dark theme variations)
html[data-theme='dark'] {
  .validateQuery {
    .queryBuilder {
      .rule.queryBuilder-invalid {
        .rule-value {
          &::placeholder {
            color: color.scale(rebeccapurple, $lightness: 30%);
          }
        }
      }
    }
  }
}

// Styles for when "Justified layout" option is selected
.justifiedLayout {
  .queryBuilder {
    // Push the clone, lock, and remove buttons to the right edge
    .ruleGroup-addGroup + button.ruleGroup-cloneGroup,
    .ruleGroup-addGroup + button.ruleGroup-lock,
    .ruleGroup-addGroup + button.ruleGroup-remove,
    .rule-operators + button.rule-cloneRule,
    .rule-operators + button.rule-lock,
    .rule-operators + button.rule-remove,
    .rule-value + button.rule-cloneRule,
    .rule-value + button.rule-lock,
    .rule-value + button.rule-remove,
    .control + button.rule-cloneRule,
    .control + button.rule-lock,
    .control + button.rule-remove,
    .chakra-select__wrapper + button.rule-cloneRule,
    .chakra-select__wrapper + button.rule-lock,
    .chakra-select__wrapper + button.rule-remove {
      margin-left: auto;
    }
  }
}

@import './icons.scss';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import './bootstrap.scss';
@import './app.scss';

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}
svg > rect:first-child {
  fill-opacity: 0 !important;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: 0px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999 !important;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}
.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
  z-index: 5000 !important;
}
.rangeslider__handle:focus {
  outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
.search-label {
  float: right;
}
.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}
.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

.listado-division {
  padding-bottom: 5.6px;
  padding-left: 24px;
  padding-right: 24px;
}

.con-alerta {
  padding-top: 70px;
}

.date-picker-range-classname > * {
  display: flex;
}

#date-picker-input-id-start + .cds--date-picker__icon {
  display: none;
}

#date-picker-input-id-finish + .cds--date-picker__icon {
  display: none;
}

.boton-filtrar-fecha {
  padding-top: 0 !important;
}

.unread-number {
  background: #25d366;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  line-height: 1.4em;
  text-align: center;
  width: 1.4em;
  height: auto;
}

.no-hover:hover,
.no-hover:not(:hover) {
  border: 0 !important;
}

.no-resize {
  resize: none;
}

input[type="checkbox"] {
  vertical-align:middle;
}

.nota-privada {
  background-color: #FEEDAF !important;
}

.conversacion-activa {
  background-color: white;
}

.rhap_main {
  width: 15vw;
}

.rhap_container {
  box-shadow: none !important;
  background-color: transparent !important;
  width: 20vw;
  align-items: center;
}

.message-file {
  background-color: #f2f2f2;
  padding: 3px;
  border-radius: 6px 6px 6px 6px;
}

.link-file:hover {
  text-decoration: none;
}

.nombre-capitalize {
  text-transform: capitalize;
}

#boton-enviar-plantilla:disabled {
  background-color: rgb(239, 242,242);
  color: rgb(33,37,41);
}

.slide-but * {
  box-sizing: border-box;
}

.slide-but {
  background: #eef0f7!important;
  border: none;
  border-radius: 12px!important;
  box-shadow: inset 0 4px 4px rgba(0,0,0,.1)!important;
  color: #eef0f7!important;
  padding: 16px!important;
}

.slide-but * {
  pointer-events: none;
  user-select: none;
}

.slide-but .slide-overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 2rem;
  background: #444;
  transition: width 0.2s ease-out;
  will-change: width;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-but .slide-overlay .slide-overlay-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.slide-but .slide-overlay .slide-caret-wrapper {
  position: absolute;
  max-width: 45px;
  min-width: 0;
  right: 0;
  top: 50%;
  height: 100%;
  transform: translateY(-50%);
  background: #444;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
}

.slide-but .slide-overlay .slide-overlay-txt {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.custom-caret-list {
  background: #0e65a3 !important;
  border-radius: 12px !important;
}

.placeholder-dinamic {
  color: transparent;
  background: linear-gradient(to right, #D3D3D3, #F0F0F0, #D3D3D3, #F0F0F0);
  background-size: 400% 400%;
  -webkit-animation: Gradient 3s ease infinite;
  -moz-animation: Gradient 3s ease infinite;
  animation: Gradient 3s ease infinite;
  }

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}